import { EventEmitter, Injectable, Output, inject } from '@angular/core';
import {
  CreateProfileGQL,
  DeleteProfileGQL,
  DestroyAllCloudyMediaGQL,
  GetProfileDetailedGQL,
  Profile,
  PurgeProfileGQL,
} from '@designage/gql';
import { IProfileForm } from '@desquare/interfaces';
import { lastValueFrom } from 'rxjs';
import { CurrentUserService } from '../current-user/current-user.service';
import { ToasterService } from '../toaster/toaster.service';
import { ApolloError } from '@apollo/client';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  createProfileGQL = inject(CreateProfileGQL);
  toasterService = inject(ToasterService);

  constructor(
    public getProfile: GetProfileDetailedGQL,
    private deleteProfile: DeleteProfileGQL,
    public destroy: DestroyAllCloudyMediaGQL,
    public purge: PurgeProfileGQL,
  ) {}
  /** tell opened list components to refresh */
  @Output() profileListChanged = new EventEmitter(); // <boolean>();

  async createProfile(form: IProfileForm) {
    console.log('createProfile', form);

    const query = await lastValueFrom(
      this.createProfileGQL.mutate({
        input: {
          name: form.name,
          locationInput: form.locationInput,
          ownerId: form.ownerId,
          userIds: form.userIds,
          description: form.description,
          organizationUid: form.organizationUid,
        },
      }),
    )
      .then(({ data }) => {
        if (
          data &&
          data.createProfile.isSuccessful &&
          data.createProfile.profile
        ) {
          this.toasterService.success('CREATE_PROFILE_SUCCESS');
          this.profileListChanged.emit();
          return data.createProfile.profile;
        } else {
          this.toasterService.error('UNKNOWN_ERROR');
          throw new Error('Unknown error');
        }
      })
      .catch((error: ApolloError) => {
        error.graphQLErrors.forEach((gqlError) => {
          console.error('createProfile', gqlError);
          this.toasterService.handleGqlError(gqlError);
        });
        return undefined;
      });
    return query;
  }
}
