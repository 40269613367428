import { Injectable, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
  IUser,
  IZoneResolution,
  ISplit,
  IProfile,
  IFeature,
} from '@desquare/interfaces';
import {
  Maybe,
  GetUserDetailsGQL,
  Profile,
  MeGQL,
  Media,
  GetActiveProfileDocument,
  WriteActiveProfileToCacheGQL,
  MeProfileFragment,
  GetMeProfilesGQL,
  CustomFeatures,
  GetActiveProfileGQL,
  GetProfileDetailedGQL,
  CurrentUserFragment,
} from '@designage/gql';
import {
  Permissions,
  Roles,
  SortOptions,
  WatchTowerViews,
} from '@desquare/enums';
import { LocalStorageService } from 'ngx-webstorage';
import { SubSink } from 'subsink';
import { IProfileState, IUserState, IAppState } from '@desquare/store/states';
import { Store } from '@ngrx/store';
import { userActions } from '@desquare/store/actions';
import { localStorageKeys } from '@desquare/constants';
import { Router } from '@angular/router';
import { BehaviorSubject, defer, lastValueFrom, map, of, tap } from 'rxjs';
import { ProfileSettingsService } from '../profile-settings/profile-settings.service';
import { SessionService } from '../session/session.service';
import { use } from 'echarts';
import { timeout } from '@desquare/utils';

import {
  sessionUser,
  sessionProfile,
  sessionUserProfiles,
} from '../session/session';
import { EncryptionService } from '../encryption/encryption.service';
import { UiDataService } from '../ui-data/ui-data.service';
import { ProfileService } from '../profile/profile.service';

export interface UiMode {
  simpleUiMode: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class CurrentUserService implements OnDestroy {
  private subs = new SubSink();
  private currentProfile$ = new BehaviorSubject<Maybe<IProfile>>(null);
  private uiMode$ = new BehaviorSubject<UiMode>({ simpleUiMode: false });

  @Output() profilePictureUrl = new EventEmitter<string>();
  @Output() profileValueChanges = new EventEmitter<Maybe<Profile>>();
  @Output() profilesChanges = new EventEmitter();
  @Output() pageChanges = new EventEmitter<number>();

  preferredPageSize!: number;
  preferredPage!: number;
  defaultPlaylistDisplay!: IZoneResolution;
  currentSortOption = SortOptions.PING_OLDEST;
  currentWatchTowerView = WatchTowerViews.DEVICE_LIST;
  viewMode!: ISplit;
  localStorageName = 'dashboardViewMode';
  // TODO: substitute this with proper Apollo Cache usage
  media: Maybe<Media[]>;

  get currentUser() {
    return sessionUser();
  }
  get currentProfile() {
    return sessionProfile();
  }
  get currentUserProfiles() {
    return sessionUserProfiles();
  }

  constructor(
    private profileService: ProfileService,
    private localStorageService: LocalStorageService,
    private getUserGQL: GetUserDetailsGQL,
    private store: Store<IAppState & IUserState & IProfileState>,
    private writeActiveProfileToCache: WriteActiveProfileToCacheGQL,
    private meGQL: MeGQL,
    private meProfilesGQL: GetMeProfilesGQL,
    private router: Router,
    private getActiveProfileGQL: GetActiveProfileGQL,
    private encryptionService: EncryptionService,
    private profileSettingsService: ProfileSettingsService,
    private uiDataService: UiDataService,
  ) {
    this.initVariables();
    this.initSubscriptions();
  }

  async refreshCurrentUserProfiles() {
    const profiles = await lastValueFrom(this.fetchSessionProfile());
    sessionUserProfiles.set(profiles.allProfiles || []);
  }
  /** prepare session user and profile */
  async initCurrentUser() {
    const profiles = await lastValueFrom(this.fetchSessionProfile());
    sessionUserProfiles.set(profiles.allProfiles || []);
    sessionProfile.set(profiles.currentProfile);
    const user = (await lastValueFrom(this.fetchSessionUser())) as IUser;
    sessionUser.set(user);
  }

  /** fetches all profiles, returns the one saved in local storage or first active and if needed update local storage */
  private fetchSessionProfile() {
    return this.meProfilesGQL.fetch({}, { fetchPolicy: 'network-only' }).pipe(
      map(({ data }) => {
        const storedId = this.localStorageService.retrieve(
          localStorageKeys.ACTIVE_PROFILE_ID,
        );
        const profiles = data.me?.profiles;
        let currentProfile = profiles?.results.find((x) => x.id === storedId);
        if (!currentProfile) {
          currentProfile = profiles?.results.find((x) => x.active);
        }
        if (!storedId || storedId !== currentProfile?.id) {
          // set profile id for next gql queries
          this.localStorageService.store(
            localStorageKeys.ACTIVE_PROFILE_ID,
            currentProfile?.id,
          );
        }

        // needed? maybe not... logic moved from topBar
        if (currentProfile) {
          this, this.setActiveProfile(currentProfile);
        }

        return {
          allProfiles: profiles?.results,
          currentProfile: currentProfile ? (currentProfile as IProfile) : null,
        };
      }),
    );
  }

  /** before calling this make sure to set current profile for proper role fetching */
  private fetchSessionUser() {
    return this.meGQL.fetch(undefined, { fetchPolicy: 'network-only' }).pipe(
      map(({ data }) => {
        return data.me as CurrentUserFragment;
      }),
    );
  }

  private getCurrentUserProfiles() {
    return this.meProfilesGQL
      .watch({}, { fetchPolicy: 'network-only' })
      .valueChanges.pipe(map((result) => result.data.me?.profiles));
  }

  /** fast sync call */
  getCurrentProfileId(): string {
    return this.localStorageService.retrieve(
      localStorageKeys.ACTIVE_PROFILE_ID,
    );
  }

  getCurrentProfile() {
    return defer(() => {
      if (this.currentProfile) return of(this.currentProfile ?? null);

      return this.getCurrentUserProfiles().pipe(
        map((profiles) => {
          const storedId = this.getCurrentProfileId();
          const currentProfile = profiles?.results.find(
            (x) => x.id === storedId,
          );

          return currentProfile ? (currentProfile as IProfile) : null;
        }),
      );
    });
  }

  getCurrentProfile$() {
    return this.currentProfile$.asObservable();
  }

  getActiveProfile() {
    return this.getActiveProfileGQL.watch().valueChanges;
  }

  private standardFeatures = [CustomFeatures.LayoutRegions];
  isFeatureEnabled(feature: CustomFeatures) {
    const profile = sessionProfile();
    return profile
      ? this.standardFeatures.includes(feature) ||
          profile.features.filter((x) => x.id === feature).length > 0
      : false;
  }

  /** DEPRECATED use isFeatureEnabled */
  isFeatureEnabled$(feature: CustomFeatures) {
    /** active for everyone */

    return this.getCurrentProfile().pipe(
      map((profile) =>
        profile
          ? this.standardFeatures.includes(feature) ||
            profile.features.filter((x) => x.id === feature).length > 0
          : false,
      ),
    );
  }

  get profiles() {
    if (this.currentUser && this.currentUser.profiles) {
      return this.currentUser.profiles.results || [];
    }

    return [];
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initVariables() {
    this.preferredPageSize =
      this.localStorageService.retrieve(localStorageKeys.PREFERRED_PAGE_SIZE) ||
      10;
    this.preferredPage =
      this.localStorageService.retrieve(localStorageKeys.PREFERRED_PAGE) || 1;
    this.currentWatchTowerView =
      this.localStorageService.retrieve(localStorageKeys.WATCHTOWER_VIEW) ||
      WatchTowerViews.DEVICE_LIST;
    this.currentSortOption =
      this.localStorageService.retrieve(localStorageKeys.SORT_OPTION) ||
      SortOptions.PING_OLDEST;
    const uiModeConfig = this.localStorageService.retrieve(
      localStorageKeys.USER_UI_MODE,
    );
    if (uiModeConfig) {
      this.uiMode$.next(uiModeConfig);
    }
  }

  initSubscriptions() {
    // this.subs.sink = this.getCurrentUser().subscribe();

    this.subs.sink = this.profileService.profileListChanged.subscribe(() => {
      this.refreshCurrentUserProfiles();
    });
    this.subs.sink = this.localStorageService
      .observe(localStorageKeys.PREFERRED_PAGE_SIZE)
      .subscribe((size) => {
        this.preferredPageSize = size;
      });

    if (!this.router.url.includes('embed')) {
      // these subs are not needed in an embedded map
      this.subs.sink = this.localStorageService
        .observe(localStorageKeys.WATCHTOWER_VIEW)
        .subscribe((value: WatchTowerViews) => {
          this.setWatchTowerView(value);
        });

      // TODO: this should be probably moved together with filters
      this.subs.sink = this.localStorageService
        .observe(localStorageKeys.SORT_OPTION)
        .subscribe((value: SortOptions) => {
          this.setSortOption(value);
        });
    }

    this.subs.sink = this.localStorageService
      .observe(localStorageKeys.PREFERRED_PAGE)
      .subscribe((page) => {
        this.preferredPage = page;
      });

    this.subs.sink = this.profilePictureUrl.subscribe((url: string) => {
      if (this.currentUser?.profilePicture) {
        this.currentUser.profilePicture.url = url;
      }
    });
  }

  getProfileFeatures(profile: MeProfileFragment) {
    const clean: IFeature[] = [];
    profile?.features?.forEach((f) => {
      if (f) {
        clean.push({ id: f.id });
      }
    });
    return clean;
  }

  setActiveProfileObs(profile: MeProfileFragment) {
    sessionProfile.set(profile as IProfile);
    this.localStorageService.store(
      localStorageKeys.ACTIVE_PROFILE_ID,
      profile.id,
    );
    this.profileSettingsService.setProfileTimeFormatByKey(
      profile.settings?.timeFormat,
    );
    this.profileSettingsService.setProfileDateFormatByKey(
      profile.settings?.dateFormat,
    );
    this.profileSettingsService.setProfileTimezone(
      profile.settings?.timeZone || '',
    );
    this.uiDataService.closeSelectedDevicePanel();

    return this.writeActiveProfileToCache.mutate(
      { input: profile },
      {
        refetchQueries: [
          { query: this.getActiveProfileGQL.document },
          { query: this.meGQL.document },
        ],
        awaitRefetchQueries: true,
      },
    );
  }

  async setActiveProfile(profile: MeProfileFragment) {
    return lastValueFrom(this.setActiveProfileObs(profile));
  }
  async setActiveProfileById(id: string) {
    const { data } = await lastValueFrom(
      this.profileService.getProfile.fetch({ id }),
    );
    if (data.profile) {
      return this.setActiveProfile(data.profile);
    }
  }

  setWatchTowerView(
    watchTowerView: WatchTowerViews = WatchTowerViews.DEVICE_LIST,
  ) {
    return;
    this.localStorageService.store(
      localStorageKeys.WATCHTOWER_VIEW,
      watchTowerView,
    );
    this.currentWatchTowerView = watchTowerView;
    if (this.currentWatchTowerView === WatchTowerViews.DEVICE_LIST) {
      this.router.navigate(['/watchtower']);
    } else if (this.currentWatchTowerView === WatchTowerViews.MAP_VIEW) {
      this.router.navigate(['/map']);
    } else if (this.currentWatchTowerView === WatchTowerViews.SPLIT_VIEW) {
      this.router.navigate(['/dashboard2']);
    }
  }

  setSortOption(sortOption: SortOptions = SortOptions.PING_OLDEST) {
    this.localStorageService.store(localStorageKeys.SORT_OPTION, sortOption);
    this.currentSortOption = sortOption;
  }

  getFirstActiveProfile() {
    const activeProfile = this.profiles.find((x) => x.active);
    return activeProfile || null;
  }

  removeProfile(id: string) {
    if (
      this.currentUser &&
      this.currentUser.profiles &&
      this.currentUser.profiles.results
    ) {
      this.currentUser.profiles.results =
        this.currentUser.profiles.results.filter((x) => x && x.id !== id);
      this.store.dispatch(
        userActions.setActiveUser({ user: this.currentUser }),
      );
      this.profilesChanges.emit();
    }
  }

  updateProfile(profile: Partial<Profile>) {
    if (sessionProfile()?.id === profile.id) {
      sessionProfile.set(profile as IProfile);
    }
    if (profile.settings) {
      this.profileSettingsService.setProfileTimeFormatByKey(
        profile.settings.timeFormat,
      );
      this.profileSettingsService.setProfileDateFormatByKey(
        profile.settings.dateFormat,
      );
      this.profileSettingsService.setProfileTimezone(
        profile.settings.timeZone || '',
      );
    }
    if (this.currentUser?.profiles?.results) {
      const profileToUpdateIndex = this.currentUser.profiles.results.findIndex(
        (x) => x.id === profile.id,
      );
      const newProfiles = [...this.currentUser.profiles.results];
      if (profileToUpdateIndex >= 0 && newProfiles.length) {
        newProfiles[profileToUpdateIndex] = {
          ...newProfiles[profileToUpdateIndex],
          ...profile,
        };
        this.currentUser.profiles.results = newProfiles;
        this.localStorageService.store(
          localStorageKeys.CURRENT_PROFILE,
          newProfiles[profileToUpdateIndex],
        );
        this.localStorageService.store(
          localStorageKeys.CURRENT_USER,
          this.currentUser,
        );
        this.profilesChanges.emit();
      }
    }
  }

  get roles() {
    if (
      this.currentUser &&
      this.currentUser.auth0Profile &&
      this.currentUser.auth0Profile.roles
    ) {
      return this.currentUser.auth0Profile.roles.map((x) => x.name);
    }
  }

  get isSuperAdmin() {
    return !!(this.roles && this.roles.includes(Roles.SUPER_ADMIN));
  }

  get isReseller() {
    return !!(this.roles && this.roles.includes(Roles.RESELLER));
  }

  get isProfileManager() {
    return !!(this.roles && this.roles.includes(Roles.PROFILE_MANAGER));
  }

  get canEditMedia() {
    return (
      this.hasPermissions([Permissions.EDIT_MEDIA]) ||
      this.isSuperAdmin ||
      this.isProfileManager
    );
  }

  get canEditTemplate() {
    return (
      this.hasPermissions([Permissions.EDIT_TEMPLATE]) ||
      this.isSuperAdmin ||
      this.isProfileManager
    );
  }

  get isContentManager() {
    return !!(this.roles && this.roles.includes(Roles.CONTENT_MANAGER));
  }

  get canViewProfileList() {
    return this.isSuperAdmin || this.profiles.length > 1;
    // TODO: Redo logic for displaying profiles list when
    // return (
    //   this.hasPermissions([Permissions.READ_PROFILES, Permissions.READ_ANYTHING]) &&
    //   (this.isSuperAdmin || (this.isProfileManager && this.profiles && this.profiles.length > 1))
    // );
  }

  get canViewUserList() {
    return (
      this.hasPermissions([
        Permissions.READ_USERS,
        Permissions.READ_ANYTHING,
      ]) ||
      this.isSuperAdmin ||
      this.isProfileManager
    );
  }

  get canViewOrganizationList() {
    return (
      this.hasPermissions([
        Permissions.READ_ORGANIZATIONS,
        Permissions.READ_ANYTHING,
      ]) || this.isSuperAdmin
    );
  }

  get canViewLocationList() {
    return (
      this.hasPermissions([
        Permissions.READ_LOCATIONS,
        Permissions.READ_ANYTHING,
      ]) ||
      this.isSuperAdmin ||
      this.isProfileManager
    );
  }

  get canManageLocations() {
    return (
      this.hasPermissions([
        Permissions.CREATE_LOCATIONS,
        Permissions.UPDATE_LOCATIONS,
        Permissions.DELETE_LOCATIONS,
        Permissions.CREATE_ANYTHING,
        Permissions.UPDATE_ANYTHING,
        Permissions.DELETE_ANYTHING,
      ]) ||
      this.isProfileManager ||
      this.isSuperAdmin
    );
  }

  get canViewPlayList() {
    return (
      this.hasPermissions([
        Permissions.READ_PLAYLISTS,
        Permissions.READ_ANYTHING,
      ]) ||
      this.isSuperAdmin ||
      this.isProfileManager
    );
  }

  get canManagePlaylist() {
    return this.hasPermissions([
      Permissions.CREATE_PLAYLISTS,
      Permissions.UPDATE_PLAYLISTS,
      Permissions.DELETE_PLAYLISTS,
      Permissions.CREATE_ANYTHING,
      Permissions.UPDATE_ANYTHING,
      Permissions.DELETE_ANYTHING,
    ]);
  }

  get canManageProfiles() {
    return (
      this.hasPermissions([Permissions.CREATE_PROFILES]) || this.isSuperAdmin
    );
  }

  get canManageMedias() {
    return this.hasPermissions([
      Permissions.CREATE_MEDIA,
      Permissions.UPDATE_MEDIA,
      Permissions.DELETE_MEDIA,
      Permissions.CREATE_ANYTHING,
      Permissions.UPDATE_ANYTHING,
      Permissions.DELETE_ANYTHING,
    ]);
  }

  get canManageChannels() {
    return (
      this.hasPermissions([
        Permissions.CREATE_CHANNELS,
        Permissions.UPDATE_CHANNELS,
        Permissions.DELETE_CHANNELS,
        Permissions.CREATE_ANYTHING,
        Permissions.UPDATE_ANYTHING,
        Permissions.DELETE_ANYTHING,
      ]) ||
      this.isProfileManager ||
      this.isSuperAdmin
    );
  }

  /** unsafe, use only in templates related functions (repeated calls) */
  get features() {
    return this.currentProfile?.features
      ? this.currentProfile.features.map((x) => x.id)
      : [];
  }

  /** unsafe, use only in templates (repeated calls) */
  get canManageEvents() {
    return (
      this.features.includes(CustomFeatures.EventChannels) &&
      (this.hasPermissions([
        Permissions.CREATE_CHANNELS,
        Permissions.UPDATE_CHANNELS,
        Permissions.DELETE_CHANNELS,
        Permissions.CREATE_ANYTHING,
        Permissions.UPDATE_ANYTHING,
        Permissions.DELETE_ANYTHING,
      ]) ||
        this.isProfileManager)
    );
  }

  get canManageOrganizations() {
    return this.isSuperAdmin;
  }

  get canManageUsers() {
    return (
      this.hasPermissions([
        Permissions.CREATE_USERS,
        Permissions.UPDATE_USERS,
        Permissions.DELETE_USERS,
        Permissions.CREATE_ANYTHING,
        Permissions.UPDATE_ANYTHING,
        Permissions.DELETE_ANYTHING,
      ]) ||
      this.isSuperAdmin ||
      this.isProfileManager
    );
  }

  get canManageDevices() {
    return (
      this.hasPermissions([
        Permissions.CREATE_DEVICES,
        Permissions.UPDATE_DEVICES,
        Permissions.DELETE_DEVICES,
        Permissions.CREATE_ANYTHING,
        Permissions.UPDATE_ANYTHING,
        Permissions.DELETE_ANYTHING,
      ]) ||
      this.isProfileManager ||
      this.isSuperAdmin
    );
  }

  get canManageAssets() {
    return this.hasPermissions([
      Permissions.CREATE_VIEWS,
      Permissions.UPDATE_VIEWS,
      Permissions.DELETE_VIEWS,
      Permissions.CREATE_ANYTHING,
      Permissions.UPDATE_ANYTHING,
      Permissions.DELETE_ANYTHING,
    ]);
  }

  get canManageChannelGroups() {
    return this.hasPermissions([
      Permissions.CREATE_CHANNEL_GROUPS,
      Permissions.UPDATE_CHANNEL_GROUPS,
      Permissions.DELETE_CHANNEL_GROUPS,
      Permissions.CREATE_ANYTHING,
      Permissions.UPDATE_ANYTHING,
      Permissions.DELETE_ANYTHING,
    ]);
  }

  get canCreateChannelGroup() {
    return this.hasPermissions([
      Permissions.CREATE_CHANNEL_GROUPS,
      Permissions.CREATE_ANYTHING,
    ]);
  }

  get canViewProfiles() {
    return this.hasPermissions(Permissions.READ_PROFILES);
  }

  get canViewDevices() {
    return (
      this.hasPermissions([
        Permissions.READ_ANYTHING,
        Permissions.READ_DEVICES,
      ]) ||
      this.isProfileManager ||
      this.isSuperAdmin
    );
  }

  get canViewChannels() {
    return (
      this.hasPermissions([
        Permissions.READ_CHANNELS,
        Permissions.READ_ANYTHING,
      ]) && !this.isContentManager
    );
  }

  get canViewChannelGroups() {
    return this.hasPermissions([
      Permissions.READ_CHANNEL_GROUPS,
      Permissions.READ_ANYTHING,
    ]);
  }

  get canUpdateRoles() {
    return (
      this.hasPermissions([Permissions.UPDATE_ROLES]) ||
      this.isSuperAdmin ||
      this.isProfileManager
    );
  }

  get defaultProfile() {
    if (this.profiles.length > 0) {
      return this.profiles[0];
    }

    return null;
  }

  async getProfilePicture(selectedUser: IUser) {
    const auth0Profile = selectedUser.auth0Profile;

    if (auth0Profile) {
      const defaultProfilePicture = auth0Profile.picture;
      const { data } = await lastValueFrom(
        this.getUserGQL.fetch({ id: selectedUser.id }),
      );
      const user = data.user;

      return user && user.profilePicture
        ? user.profilePicture.url
        : defaultProfilePicture;
    }

    return null;
  }

  private get permissions() {
    if (
      this.currentUser &&
      this.currentUser.auth0Profile &&
      this.currentUser.auth0Profile.permissions
    ) {
      return this.currentUser.auth0Profile.permissions.map(
        (x) => x.permission_name,
      );
    }
  }

  hasPermissions(permissions: Permissions[] | Permissions) {
    if (this.permissions) {
      if (permissions instanceof Array) {
        return this.permissions.some((x) =>
          Object.values(permissions).includes(x as Permissions),
        );
      } else {
        return this.permissions.includes(permissions);
      }
    }

    return false;
  }

  setPreferredPageSize(size: number) {
    this.localStorageService.store(localStorageKeys.PREFERRED_PAGE_SIZE, size);
  }

  setPreferredPage(page: number) {
    this.localStorageService.store(localStorageKeys.PREFERRED_PAGE, page);
    this.pageChanges.emit(page);
  }

  get userId() {
    return this.currentUser?.id;
  }

  getUiMode() {
    return this.uiMode$.asObservable();
  }

  setUiMode(arg: UiMode) {
    this.uiMode$.next(arg);
    this.localStorageService.store(localStorageKeys.USER_UI_MODE, arg);
  }

  navigateToChannel(channelId: string) {
    // TODO: consider resource groups security
    const cId = this.encryptionService.encrypt(channelId);
    const profileId = this.getCurrentProfileId();
    const pId = this.encryptionService.encrypt(profileId);
    if (this.canManageChannels && cId) {
      this.router.navigate(['/channels/manage', cId, pId]);
    }
  }

  navigateToPlaylist(playlistId: string) {
    // TODO: consider resource groups security
    const id = this.encryptionService.encrypt(playlistId);
    if (this.canManagePlaylist && id) {
      this.router.navigate(['/playlists/manage', id]);
    }
  }
}
